.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Ocupa toda la altura de la ventana */
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
    position: fixed; /* Posición fija para cubrir toda la pantalla */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999; /* Por encima de otros elementos */
}

.login-popup {
    background-color: white; /* Fondo blanco para el formulario */
    padding: 20px;
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra alrededor del popup */
    width: 400px; /* Ancho fijo del popup */
    max-width: 90%; /* No más del 90% del ancho de la pantalla */
    text-align: center; /* Centrar texto */
}

h2 {
    margin-bottom: 20px; /* Espacio debajo del título */
}

form {
    display: flex;
    flex-direction: column;
    width: 100%; /* Ancho completo del formulario */
}

label {
    margin-bottom: 5px; /* Espacio debajo de las etiquetas */
}

input {
    padding: 10px;
    margin-bottom: 15px; /* Espacio debajo de los inputs */
    border: 1px solid #ccc; /* Borde del input */
    border-radius: 4px; /* Bordes redondeados */
}

button {
    padding: 10px;
    background-color: #007bff; /* Color de fondo del botón */
    color: white; /* Color del texto del botón */
    border: none; /* Sin borde */
    border-radius: 4px; /* Bordes redondeados */
    cursor: pointer; /* Cambia el cursor al pasar el mouse */
}

button:hover {
    background-color: #0056b3; /* Color de fondo al pasar el mouse */
}
