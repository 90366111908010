/* src/dashboard/Dashboard.css */

/* Estilos del dropdown */
.dropdown {
    position: absolute; /* Asegura que el dropdown se posicione correctamente */
    right: 0; /* Alinea el dropdown a la derecha */
    background-color: #fff; /* Color de fondo blanco */
    border: 1px solid #ccc; /* Borde gris claro */
    z-index: 1000; /* Para que esté por encima de otros elementos */
    border-radius: 0; /* Bordes redondeados eliminados */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Sombra sutil */
    margin-top: 10px; /* Espacio entre el avatar y el dropdown */
    width: 150px; /* Ancho del dropdown */
    padding: 5px 0; /* Espaciado interno */
}

.logout-button {
    display: block; /* Asegura que el botón ocupe el ancho completo */
    background-color: white; /* Color de fondo blanco */
    color: black; /* Color del texto */
    border: none; /* Sin borde */
    padding: 10px; /* Espaciado interno */
    text-align: left; /* Alineación del texto a la izquierda */
    cursor: pointer; /* Cambia el cursor al pasar por encima */
    transition: background-color 0.3s; /* Transición suave para el color de fondo */
}

.logout-button:hover {
    background-color: lightgray; /* Cambia el color al pasar el mouse sobre el botón */
}

.left-panel {
    background-color: #FAF3E0; /* Color marfil suave */
    width: 20%; /* 20% del ancho de la página */
    position: absolute; /* Posicionarlo absoluto */
    top: 42px; /* Espaciado desde la parte superior (debajo del header) */
    height: calc(100% - 42px - 5%); /* Altura hasta el footer */
    overflow-y: auto; /* Para que sea scrollable si el contenido es demasiado */
    left: 0; /* Alinearlo a la izquierda */
}

/* Estilo para el panel superior */
.top-panel {
    height: 50px; /* Altura del header */
    display: flex; /* Para alinear elementos en fila */
    align-items: center; /* Centra verticalmente */
    justify-content: space-between; /* Espacia los elementos en los extremos */
    padding: 0 20px; /* Espaciado interno */
    position: relative; /* Posicionamiento relativo para evitar solapamientos */
    z-index: 10; /* Para asegurar que esté por encima de otros elementos */
}

/* General */
.dashboard {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Asegura que cubra toda la altura de la ventana */
}

footer {
    height: 5%;
    text-align: center;
    padding: 10px;
    position: relative; /* Asegura que el footer esté bien posicionado */
    bottom: 0; /* Asegura que esté siempre en la parte inferior */
    width: 100%; /* Asegura que el footer ocupe todo el ancho */
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Agrega sombra si es necesario */
}

/* Cambiar el color del header y el footer con brillo radial */
.top-panel, footer {
    background: radial-gradient(circle, rgba(5, 17, 99, 1) 0%, rgba(5, 17, 99, 0.8) 50%, rgba(5, 17, 99, 0.6) 100%);
    color: white; /* Cambia el color del texto si es necesario */
}

/* Estilos para el contenido principal del Dashboard */
.content {
    flex: 1; /* Permite que el contenido ocupe el espacio restante */
    padding: 20px; /* Espaciado interno para el contenido */
    background-color: #ffffff; /* Fondo blanco para el contenido */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Sombra sutil para darle profundidad */
    border-radius: 5px; /* Bordes redondeados para un aspecto más suave */
    overflow-y: auto; /* Permite scroll si el contenido es demasiado grande */
    margin-left: 250px; /* Asegura que el contenido no solape el panel izquierdo */
    margin-top: 20px; /* Espacio adicional desde la parte superior */
    margin-bottom: 10px; /* Espacio desde la parte inferior */
    height: calc(100% - 42px - 10px); /* Altura ajustada, considerando el panel superior y margen */
}

/* Evitar que el contenido afecte otros elementos */
.content > * {
    margin-bottom: 15px; /* Espacio entre los elementos hijos */
}

/* Asegurar que los encabezados en el contenido tengan un margen adecuado */
.content h2,
.content h3,
.content h4 {
    margin: 0; /* Elimina el margen por defecto */
    color: #333; /* Color del texto para títulos */
}

/* Mejorar la legibilidad del texto en párrafos */
.content p {
    line-height: 1.6; /* Mejora la legibilidad del texto */
    color: #555; /* Color del texto para párrafos */
    margin: 0; /* Elimina el margen por defecto para evitar desbordamiento */
}

/* Ajustar estilos para listas */
.content ul, 
.content ol {
    padding-left: 20px; /* Asegura un buen espaciado en listas */
    color: #333; /* Color del texto para listas, asegúrate de que sea visible */
}

/* Estilos específicos para elementos de lista */
.content li {
    margin-bottom: 5px; /* Espacio entre los elementos de la lista */
    color: #333; /* Color del texto para elementos de lista */
}
